import React from 'react'
import { Box, Text, IconButton } from '../UI'

const Notification = ({ text, onClick, bg, children }) => (
  <Box display="flex" p={2} my={2} borderRadius={1} bg={bg || 'tomato'}>
    <Box alignSelf="center" flex={1} mr={'xs'}>
      {children ? (
        children
      ) : (
        <Text mb={0} fontSize={2} color="white">
          {text}
        </Text>
      )}
    </Box>
    <IconButton
      bg="secondary.1"
      color={'tomato'}
      round
      name="times"
      aria-label="Close"
      onClick={onClick}
    />
  </Box>
)

export default Notification
