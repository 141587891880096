import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby-link'
import { Box, Flex, Text, Button, IconButton } from '../UI'
import styled from 'styled-components'
import Notification from '../Notification'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = ({ beforeForm, formData, extraState }) => {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const resetForm = () => {
    setState({
      formMessage: 'Your message was sent, thanks for getting in touch.',
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
        extraInfo: JSON.stringify(extraState),
      }),
    })
      .then(
        () => resetForm() // navigate(form.getAttribute('action')
      )
      .catch(error =>
        setState({
          formMessage: 'Sorry, something went wrong. Please try again later.',
        })
      )
  }
  const hideMessage = () => {
    setState({})
  }

  return (
    <Box
      as="form"
      width={1}
      method="POST"
      name={formData.name}
      action={formData.action}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {beforeForm && beforeForm()}

      <input type="hidden" name="form-name" value={formData.formName} />

      <Flex flexDirection="column" alignItems="space-around">
        <Box display={'none'}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </Box>

        {formData.inputs.map(({ label, id, textProps, inputProps }) => (
          <React.Fragment key={id}>
            <Text
              fontSize={1}
              pb={1}
              fontWeight={'bold'}
              color={'primary.0'}
              as="label"
              htmlFor={id}
              {...textProps}
            >
              {label}
            </Text>
            <Input
              id={id}
              name={id}
              onChange={handleChange}
              mb={2}
              p={1}
              pl={2}
              borderRadius={3}
              border={'1px solid'}
              borderColor={'lightgrey'}
              as={'input'}
              fontSize={1}
              {...inputProps}
            />
          </React.Fragment>
        ))}
        <Box>
          <Button small type="submit">
            {formData.submitLabel}
          </Button>
        </Box>
        {state.formMessage && (
          <Notification text={state.formMessage} onClick={hideMessage} />
        )}
      </Flex>
    </Box>
  )
}

const Input = styled(Box)`
  outline: none;
  font-family: ${props => props.theme.fonts.sans};
  &:focus {
    border-color: ${props => props.theme.colors.secondary[2]};
    background-color: ${props => props.theme.colors.secondary[2]};
  }
`

Form.propTypes = {
  extraState: PropTypes.object,
  beforeForm: PropTypes.func,
  formData: PropTypes.shape({
    formName: PropTypes.string,
    submitLabel: PropTypes.string,
    inputs: PropTypes.array,
  }).isRequired,
}

export default Form
