import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import get from 'lodash.get'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import { useScrollToElement } from '../../helpers/useScrollToElement'
import PageLayout from '../../components/PageLayout'
import Form from '../../components/Form'
import { Box, Flex, Text, Button, Container } from '../../components/UI'
import styled from 'styled-components'
import Label from '../../components/UI/Label'

const ContactPage = ({ data: { contentfulPage: page }, location }) => {
  const SeoImg =
    get(page, 'image.childImageSharp.sizes.src') || get(page, 'image.fluid.src')

  const formRef = createRef()
  const { scrollTo } = useScrollToElement()
  useEffect(() => {
    if (formRef.current && location.state && location.state.instrumentEnquiry) {
      scrollTo(formRef.current, -100)
    }
  }, [formRef.current])

  const formData = {
    name: 'contact',
    action: '/contact',
    submitLabel: 'Submit',
    inputs: [
      {
        id: 'name',
        label: 'Name',
        textProps: {},
        inputProps: {
          type: 'text',
          required: true,
        },
      },
      {
        id: 'email',
        label: 'Email',
        textProps: {},
        inputProps: {
          type: 'email',
          required: true,
          pb: 1,
        },
      },
      {
        id: 'message',
        label: 'Message',
        textProps: {},
        inputProps: {
          as: 'textarea',
          required: true,
          minHeight: '220px',
        },
      },
    ],
  }
  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        image={SeoImg}
        pathname={page.slug}
        article
      />
      <Layout>
        <PageLayout
          horizontal={page.horizontal}
          image={SeoImg}
          page={page}
          afterContent={() => (
            <Box ref={formRef} width={1} maxWidth={600} margin="0 auto">
              <Form
                formData={formData}
                beforeForm={() =>
                  location.state &&
                  location.state.instrumentEnquiry && (
                    <Label textProps={{ fontSize: 1 }}>
                      Instrument Enquiry: {location.state.instrumentName}
                    </Label>
                  )
                }
                extraState={location.state}
              />
            </Box>
          )}
        />
      </Layout>
    </div>
  )
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Input = styled(Box).attrs(props => ({
  mb: 2,
  p: 1,
  pl: 2,
  borderRadius: 3,
  border: '1px solid',
  borderColor: 'lightgrey',
  as: 'input',
}))`
  outline: none;
  &:focus {
    border-color: ${props => props.theme.colors.secondary[2]};
    background-color: ${props => props.theme.colors.secondary[2]};
  }
`

ContactPage.propTypes = {}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      subHeading
      horizontal
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
        json
      }
    }
  }
`
